// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

import './style.css';
function NaoEncontrado() {
  const title = "Street Fighter";
  const footerText = "© 2024 All rights reserved.";
  const images = [
    'https://via.placeholder.com/800x400.png?text=Slide+1',
    'https://via.placeholder.com/800x400.png?text=Slide+2',
    'https://via.placeholder.com/800x400.png?text=Slide+3'
  ];
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Street Fighter Painéis automotivo." />
        
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Header title={title} />
      <main>
        <div className='naoEncontrado'>
          <h1 className="text-center ">
            404
          </h1>
          <h3 className="h2">
            Parece que você está perdido
          </h3>

          <p>
            a página que você está procurando não está disponível!
          </p>
          
          <a href="/" className="link_404">Ir para início</a>
        </div>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default NaoEncontrado;

//<Carousel images={images} />
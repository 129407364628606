// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

import './styles.css';
import { FaRegFilePdf } from 'react-icons/fa';
function Gabaritos() {
  const title = "Street Fighter - Gabaritos";
  const footerText = "© 2024 All rights reserved.";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Gabaritos dos painéis Street Fighter." />
      </Helmet>
      <Header title={title} />
      <main>
          <div className='tituloPagina'>
            <div>
              <a href='/'>Início</a> / Gabaritos
            </div>
            <div className='nomeTituloPagina'>
              GABARITOS
            </div>
          </div>
          <ul className='listaGabaritos'>
            <li>
              <a href="/gabarito/160.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF160
              </a>
            </li>
            <li>
              <a href="/gabarito/CafeRacer.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                Cafe Racer
              </a>
            </li>
            <li>
              <a href="/gabarito/CBInferior.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                CB visão inferior
              </a>
            </li>
            <li>
              <a href="/gabarito/CBSuperior.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                CB visão superior
              </a>
            </li>
            <li>
              <a href="/gabarito/SF1.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF1
              </a>
            </li>
            <li>
              <a href="/gabarito/SF2.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF2
              </a>
            </li>
            <li>
              <a href="/gabarito/SF3.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF3
              </a>
            </li>
            <li>
              <a href="/gabarito/SF110.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF110
              </a>
            </li>
            <li>
              <a href="/gabarito/SF125.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SF125
              </a>
            </li>
            <li>
              <a href="/gabarito/SFBros.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SFBros
              </a>
            </li>
            <li>
              <a href="/gabarito/SFMini.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SFMini
              </a>
            </li>
            <li>
              <a href="/gabarito/SFZ.pdf">
                <FaRegFilePdf className="icon" size={96}/>
                SFZ
              </a>
            </li>
          </ul>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Gabaritos;

// src/App.js

import React,{useEffect } from 'react';
import AppRoutes from './Routes';
import './App.css';
function App() {
  useEffect(() => {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;

// src/Header.js

import logoImg from '../../assets/imagens/logo.jpg'
import logoImg1 from '../../assets/imagens/logo_sem_fundo.png'
import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import { FaShoppingCart, FaUser, FaBars, FaSearch, FaTimes,FaWhatsapp } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
const Modal = ({ show, handleClose, email,setEmail, password,setPassword,passwordConfirmacao,setPasswordConfirmacao,token,setToken,isLogin,setIsLogin, loading,setLoading, nome,setNome,telefone,setTelefone,cpf,setCpf}) => {
  if (!show) return null; // Não renderiza o modal se o estado 'show' for falso
  
  const handleCadastrar = async(e) => {
    if (!email || !password||!nome||!telefone||!cpf||!passwordConfirmacao) {
      return; // Não faça nada se os campos não estiverem preenchidos
    }
    e.preventDefault();

    // Dados de login
    const credentials = { email, password,name:nome,telefone,cpfCnpj:cpf,password_confirmation: passwordConfirmacao};
    setLoading(true);
    try {
      const response = await fetch('https://api.streetfightermotoparts.com.br/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Adicione outros cabeçalhos se necessário
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (response.ok) {
        // Se a resposta for bem-sucedida, o token será armazenado
        console.log('Token JWT:', data.token);
        localStorage.setItem("token",data.token);
        localStorage.setItem("nome",data.nome);
        setToken(data.token);
        handleClose();
        setLoading(false);
      } else {
        // Caso de erro, como credenciais inválidas
        alert(JSON.stringify(data));
        setLoading(false);
      }
    } catch (err) {
      // Tratar erros de rede
      alert('Falha de conexão, tente novamente: ' + err.message);
    }
  };


  const handleLogin = async (e) => {
    if (!email || !password) {
      return; // Não faça nada se os campos não estiverem preenchidos
    }
    e.preventDefault();

    // Dados de login
    const credentials = { email, password };
    setLoading(true);
    try {
      const response = await fetch('https://api.streetfightermotoparts.com.br/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Adicione outros cabeçalhos se necessário
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (response.ok) {
        // Se a resposta for bem-sucedida, o token será armazenado
        console.log('Token JWT:', data.token);
        localStorage.setItem("token",data.token);
        localStorage.setItem("nome",data.nome);
        setToken(data.token);
        handleClose();
        setLoading(false);
      } else {
        // Caso de erro, como credenciais inválidas
        alert("Login inválido!");
        setLoading(false);
      }
    } catch (err) {
      // Tratar erros de rede
      alert('Falha de conexão, tente novamente: ' + err.message);
    }
  };
  const toggleModal = () => {
    setIsLogin(!isLogin); // Alterna entre Login e Cadastro
  };
  return (
    <div className="modal-overlay">
      <div className="modal">
        <span onClick={handleClose}>X</span>
        {isLogin ? (
          <>
            <h1>Entrar</h1>
            <form className='formLogin'>
              <div>
                E-mail
                <input
                  type="text"
                  placeholder='nome@email.com'
                  className='form-control'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                Senha
                <input
                  type="password"
                  placeholder='Sua senha'
                  className='form-control'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={true}
                />
              </div>
              <div className='esqueceuSenha'>
                <a href="#">Esqueceu a senha?</a>
              </div>
              <button type="submit" onClick={handleLogin} disabled={loading}>
                {loading ? 'Carregando...' : 'Login'}
              </button>
              <button type="button" onClick={toggleModal}>
                Não tem uma conta? Cadastre-se
              </button>
            </form>
          </>
        ) : (
          <>
            <h1>Cadastrar</h1>
            <form className='formLogin'>
              <div>
                Nome
                <input
                  type="text"
                  placeholder='João da Silva'
                  className='form-control'
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                E-mail
                <input
                  type="email"
                  placeholder='nome@email.com'
                  className='form-control'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                Telefone
                <input
                  type="text"
                  placeholder='(xx) 9 xxxx-xxxx'
                  className='form-control'
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                CPF
                <input
                  type="text"
                  placeholder='xxx.xxx.xxx-xx'
                  className='form-control'
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  required={true}
                />
              </div>
              <div>
                Senha
                <input
                  type="password"
                  placeholder='Sua senha'
                  className='form-control'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div>
                Repita a Senha
                <input
                  type="password"
                  placeholder='Sua senha'
                  className='form-control'
                  value={passwordConfirmacao}
                  onChange={(e) => setPasswordConfirmacao(e.target.value)}
                  required={true}
                />
              </div>
              <button type="submit" onClick={handleCadastrar} disabled={loading}>
                {loading ? 'Carregando...' : 'Cadastrar'}
                
              </button>
              <button type="button" onClick={toggleModal}>
                Já tem uma conta? Faça login
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
function Header({ title,abrirLogin,setAbrirLogin }) {


  
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmacao, setPasswordConfirmacao] = useState('');
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isLogin, setIsLogin] = useState(true); // Controla se estamos no modal de Login ou Cadastro
  const [showModal, setShowModal] = useState(abrirLogin?true:false);
  const [loading, setLoading] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    setIsLogin(true);
    
  };
  
  window.abrirLogin = openModal;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [freteGratis, setFreteGratis] = useState(true);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const closeFreteGratis = () => {
    setFreteGratis(false);
  };
  const quantidadeCarrinho = localStorage.getItem("quantidadeCarrinho");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  function irParaConta(){
    if(token){
      fetch('https://api.streetfightermotoparts.com.br/api/getUserFromToken', {
        method: 'GET', // O método pode ser 'GET' ou outro conforme sua API
        headers: {
          'Content-Type': 'application/json', // Tipo de conteúdo da requisição
          'Authorization': `Bearer ${token}` // Adiciona o token Bearer
        }
      })
      .then(response => response.json()) // Converte a resposta para JSON
      .then(data => {
        console.log(data);
        if(data.message){
          openModal();

        }
        else{
          navigate("/meusPedidos");
        }
        
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
      });
      
    }
    else{
      openModal();
    }
  }
  return (
    <header className="header">
      <div className={` ${freteGratis ? 'freteGratis' : 'oculto'}`}>
        <div>Frete grátis em todo os painéis. Aproveite agora!!!</div>
        <FaTimes className="icon" onClick={closeFreteGratis} />
      </div>
      <a href='https://wa.me/5531991049112' className='whatsAppIcon'>
        <FaWhatsapp className='icon' size={100}/>
      </a>
      <div className={`header-top  ${freteGratis ? 'marginFreteGratis' : ''}`}>
      <a href="/"><img src={logoImg} alt="Logo Street Fighter" height='48px'/></a>
        <div className="search-cart-login">
          <div className="search-container">
            <FaSearch className="search-icon" />
            <input type="text" className="search" placeholder="Buscar..." />
          </div>
          <a href="/carrinho" className='botaoCarrinho'>
            <FaShoppingCart className="icon" />
            {(quantidadeCarrinho && quantidadeCarrinho!==0) &&
            <span className='qtdCarrinho'>
              {quantidadeCarrinho}
            </span>
            }
          </a>
          
          <FaUser onClick={irParaConta} className="icon" />
        </div>
      </div>
      <nav className="header-bottom">
        <a href="/" className="nav-item">Inicio</a>
        <a href="/paineis" className="nav-item">Painéis</a>
        <a href="/sensores" className="nav-item">Sensores</a>
        <a href="/molduras" className="nav-item">Molduras</a>
        <a href="/acessorios" className="nav-item">Acessórios</a>
        <a href="/gabaritos" className="nav-item">Gabaritos</a>
        <a href="/instaladores" className="nav-item">Instaladores</a>
        

      </nav>
      <div className={`header-mobile ${freteGratis ? 'marginFreteGratis' : ''}`}>
        <div>
          <FaBars className="icon" onClick={toggleMenu} />
          <FaSearch className="icon" />
        </div>
        <a href="/">
          <img src={logoImg1} alt="Logo Street Fighter" height='64px'/>
        </a>
        <div>
          <a href="/carrinho" className='botaoCarrinho'>
            <FaShoppingCart className="icon" />
            {(quantidadeCarrinho && quantidadeCarrinho!==0) &&
            <span className='qtdCarrinho'>
              {quantidadeCarrinho}
            </span>
            }
          </a>
          <FaUser onClick={irParaConta} className="icon" />
        </div>
      </div>
      <nav ref={menuRef} className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>

        <div>
          <img src={logoImg1} alt="Logo Street Fighter" height='64px'/>
          <FaTimes className="close-icon" onClick={closeMenu} />
        </div>
        <a href="/" className="nav-item" onClick={closeMenu}>Inicio</a>
        <a href="/paineis" className="nav-item" onClick={closeMenu}>Painéis</a>
        <a href="/sensores" className="nav-item" onClick={closeMenu}>sensores</a>
        <a href="/molduras" className="nav-item" onClick={closeMenu}>Molduras</a>
        <a href="/acessorios" className="nav-item" onClick={closeMenu}>Acessórios</a>
        <a href="/gabaritos" className="nav-item" onClick={closeMenu}>Gabarito</a>
        <a href="/instaladores" className="nav-item" onClick={closeMenu}>Instaladores</a>
      </nav>
      <Modal show={showModal} handleClose={closeModal} email={email} setEmail={setEmail} password={password} setPassword={setPassword} passwordConfirmacao={passwordConfirmacao} setPasswordConfirmacao={setPasswordConfirmacao} token={token} setToken={setToken} isLogin={isLogin} setIsLogin={setIsLogin} loading={loading} setLoading={setLoading} nome={nome} setNome={setNome} telefone={telefone} setTelefone={setTelefone} cpf={cpf} setCpf={setCpf}/>
    </header>
    
  );
}

export default Header;

// src/Home.js

import React,{ useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
//import Carousel from '../Carousel';
import img1 from '../../assets/imagens/capa-site.d9001f9133d3d1a11c4a.webp'
import Produto from '../Produto';
import './Home.css';
function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fazendo a chamada para a API
    fetch('https://api.streetfightermotoparts.com.br/api/produtos')
      .then(response => response.json()) // Converte a resposta para JSON
      .then(data => {
        setData(data); // Armazena os dados da resposta
        setLoading(false); // Atualiza o estado para indicar que o carregamento terminou
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
        setLoading(false);
      });
  }, []); // O array vazio faz com que a chamada ocorra apenas uma vez, ao montar o componente

  if (loading) {
    return <div>Carregando...</div>;
  }
  const title = "Street Fighter";
  const footerText = "© 2024 All rights reserved.";

  function moeda(valor){
    return new Intl.NumberFormat('pt-BR', { 
      style: 'currency', 
      currency: 'BRL' 
    }).format(valor)
  }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Street Fighter Painéis automotivo." />
        <link rel="canonical" href="https://streetfightermotoparts.com.br/" />
      </Helmet>
      <Header title={title} />
      <main>
        <img width="100%" src={img1} alt="imagem 1"></img>
        <ul className='listaItens'>
        {data.map((produto) => (
          <li  key={produto.id}>
            <Produto produto={{nomeProduto:produto.nome,valorProduto:moeda(produto.valorDesconto),imagem:produto.fotoCapa,link:"/produto/"+produto.identificador}}></Produto>
          </li>
        ))}
        </ul>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Home;

//<Carousel images={images} />
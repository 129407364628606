// src/Home.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

import './style.css';
function PoliticaDevolucao() {
  const title = "Street Fighter";
  const footerText = "© 2024 All rights reserved.";
  const images = [
    'https://via.placeholder.com/800x400.png?text=Slide+1',
    'https://via.placeholder.com/800x400.png?text=Slide+2',
    'https://via.placeholder.com/800x400.png?text=Slide+3'
  ];
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Street Fighter Painéis automotivo." />
      </Helmet>

      <h1>Política de Devolução</h1>

      <p>A satisfação dos nossos clientes é nossa prioridade. Se, por qualquer motivo, você não estiver satisfeito com sua compra, oferecemos uma política de devolução fácil e sem complicações. Abaixo estão os detalhes de nossa política de devolução:</p>

      <h2>1. Prazo para Devolução</h2>
      <p>Você tem até <strong>30 dias corridos</strong> a partir da data de recebimento do produto para solicitar uma devolução ou troca. Após esse prazo, infelizmente não poderemos oferecer reembolso ou troca.</p>

      <h2>2. Condições para Devolução</h2>
      <p>Para ser elegível para uma devolução, o produto deve atender às seguintes condições:</p>
      <ul>
          <li>O produto deve estar <strong>sem uso</strong> e nas mesmas condições em que foi recebido.</li>
          <li>O produto deve estar na <strong>embalagem original</strong>, incluindo acessórios, manuais, e todos os itens que o acompanham.</li>
          <li>Produtos que apresentarem <strong>sinais de uso</strong>, danos causados pelo cliente, ou <strong>itens personalizados</strong> que não podem ser revendidos podem não ser elegíveis para devolução ou troca.</li>
      </ul>

      <h2>3. Processo de Devolução</h2>
      <p>Para iniciar o processo de devolução:</p>
      <ol>
          <li>Entre em contato com nosso suporte ao cliente através do e-mail <strong>[email da empresa]</strong> ou telefone <strong>[número de telefone]</strong>.</li>
          <li>Informe o número do pedido e o motivo da devolução.</li>
          <li>Nosso time fornecerá as instruções para envio do produto de volta.</li>
      </ol>

      <h2>4. Custos de Envio</h2>
      <p>
          <strong>Se a devolução for devido a defeito de fabricação</strong> ou envio incorreto, nós arcaremos com os custos de envio da devolução.
          <br/>
          Para outros motivos de devolução (como troca de tamanho ou desistência da compra), o custo de envio será de responsabilidade do cliente.
      </p>

      <h2>5. Reembolsos</h2>
      <p>Após o recebimento e inspeção do produto devolvido, enviaremos um e-mail notificando sobre a aprovação ou rejeição do reembolso.</p>
      <ul>
          <li>Se aprovado, o reembolso será processado e automaticamente aplicado ao método de pagamento original em até <strong>10 dias úteis</strong>.</li>
          <li>Em alguns casos, pode haver um prazo adicional até que o valor apareça na sua conta, dependendo da operadora de cartão ou instituição financeira.</li>
      </ul>

      <h2>6. Trocas</h2>
      <p>Se você precisar trocar o produto por outro tamanho, cor ou modelo, entre em contato com nosso suporte ao cliente para organizar a troca. O prazo para trocas também é de <strong>30 dias corridos</strong> após o recebimento.</p>

      <h2>7. Produtos Não Elegíveis para Devolução</h2>
      <p>Alguns itens não podem ser devolvidos, incluindo:</p>
      <ul>
          <li>Produtos personalizados ou feitos sob encomenda.</li>
          <li>Produtos em promoção ou liquidação.</li>
      </ul>

      <h2>8. Garantias</h2>
      <p>Se o produto apresentar defeitos após o período de devolução, ele poderá estar coberto por nossa garantia. Consulte a <strong>Política de Garantia</strong> para obter mais detalhes sobre o procedimento.</p>

      <h2>9. Contato</h2>
      <p>Se você tiver alguma dúvida sobre nossa política de devolução, entre em contato conosco:</p>
      <ul>
          <li><strong>E-mail:</strong> [email da empresa]</li>
          <li><strong>Telefone:</strong> [número de telefone]</li>
          <li><strong>Horário de atendimento:</strong> Segunda a sexta-feira, das 9h às 18h</li>
      </ul>

    </div>
  );
}

export default PoliticaDevolucao;

//<Carousel images={images} />
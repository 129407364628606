// src/Home.js

import React,{ useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';

import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import './styles.css';
function Carrinho() {
  const title = "Carrinho - StreetFighter";
  const footerText = "© 2024 All rights reserved.";

  const navigate = useNavigate();
  // Usando useState para armazenar o valor do input
  const [carrinho, setCarrinho] = useState(JSON.parse(localStorage.getItem("carrinho")));
  const token = localStorage.getItem("token");
  const [abrirLogin, setAbrirLogin] = useState(false);
  function comprarNoWhatsapp() {
    alert("Estamos atualizando o site, vou direcionar para um atendente concluir a etapa do pagamento manualmente com você");
    var textoMensagem = encodeURI("Olá venho através do site com interesse de compra nos seguintes itens. ")+"%0A";
    carrinho.forEach(
      function(p){
        textoMensagem += "%0A" + encodeURI(p.quantidade + " - " + p.nome + " - "+p.modelo + " - "+p.frente + " - " + p.sensor);
      }
    );

    window.open(" https://wa.me/5531991049112?text="+textoMensagem, '_blank', 'noopener,noreferrer');
    window.gtag('event', 'click', {
      'event_category': 'Carrinho',
      'event_label': 'Compra',
      'value': 1
    });
  }
  var quantidadeTotalCarrinho = 0;
  carrinho.forEach(
    function(p){
      quantidadeTotalCarrinho += parseInt(p.quantidade);
    }
  );
  var totalDesconto = 0;
  carrinho.forEach(
    function(p){
      totalDesconto += parseInt(p.quantidade)*parseFloat(p.valorDesconto);
    }
  );
  var total = 0;
  carrinho.forEach(
    function(p){
      total += parseInt(p.quantidade)*parseFloat(p.valor);
    }
  );
  function moeda(valor){
    return new Intl.NumberFormat('pt-BR', { 
      style: 'currency', 
      currency: 'BRL' 
    }).format(valor)
  }
  function finalizarCompra(){
    var tokenUsuario = localStorage.getItem("token");

    if(tokenUsuario){
      fetch('https://api.streetfightermotoparts.com.br/api/getUserFromToken', {
        method: 'GET', // O método pode ser 'GET' ou outro conforme sua API
        headers: {
          'Content-Type': 'application/json', // Tipo de conteúdo da requisição
          'Authorization': `Bearer ${tokenUsuario}` // Adiciona o token Bearer
        }
      })
      .then(response => response.json()) // Converte a resposta para JSON
      .then(data => {
        if(data.message){
          window.abrirLogin(true);

        }
        else{
          navigate("/finalizarCompra");
        }
        
      })
      .catch(error => {
        console.error('Erro ao buscar dados:', error);
      });
      
    }
    else{
      window.abrirLogin(true);
    }
  }
  function alterarQuantidade (indexCarrinho,novaQuantidade)  {
    var objeto = [];
    var totalQuantidade = 0;
    console.log(indexCarrinho);
    console.log(carrinho);
    carrinho.forEach(function(o,index){
      if(index===indexCarrinho ){
        if(novaQuantidade!==0){
          carrinho[index].quantidade=novaQuantidade;
          objeto.push(o);
          totalQuantidade+=parseInt(novaQuantidade);
        }
      }
      else{
        objeto.push(o);
        totalQuantidade+=parseInt(o.quantidade);
      }
    });
    
    localStorage.setItem("carrinho",JSON.stringify(objeto));
    localStorage.setItem("quantidadeCarrinho",totalQuantidade);
    setCarrinho(objeto);
  };
  if(!carrinho || carrinho.length===0){
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="Carrinho de compras." />
        </Helmet>
        <Header title={title} />
        <main className='cardCarrinho'>
          <h1 className='semProduto'>Sem produtos no carrinho<br/><a href="/">Voltar para tela inicial</a></h1>
        </main>
        <Footer footerText={footerText} />
      </div>
    );
  }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Carrinho de compras." />
      </Helmet>
      <Header title={title} />
      <main className='cardCarrinho'>
        <div className='tituloCarrinho'>
          Carrinho
        </div>
        <div className='carrinhoInfo'>
            <div className='listaProdutosCarrinho'>
              {
              carrinho.map((produto, indexArray) => (
                <div className='produtoCarrinho' key={indexArray}>
                  <div className='col1Produto'>
                    <img className="imagemProdutoCarrinho" src={produto.fotoCapa} alt={"Imagem do "+produto.nome}/>
                    <div className='quantidadeCarrinho'>
                      <button className='quantidadeBotao' onClick={function(){alterarQuantidade(indexArray,parseInt(produto.quantidade)-1)}}>{parseInt(produto.quantidade) === 1 ?  <FaTrash />:"-" }</button>
                      <input type="text"  className='inputQuantidade' value={produto.quantidade} onChange={function(event){alterarQuantidade(indexArray,parseInt(event.target.value))}}></input>
                      <button className='quantidadeBotao' onClick={function(){alterarQuantidade(indexArray,parseInt(produto.quantidade)+1)}}>+</button>
                    </div>
                  </div>
                  <div className='col2Produto'>

                    <h2>{produto.nome}</h2>
                    <p className='observacaoProdutoCarrinho'>
                      Modelo: {produto.modelo}<br/>
                      {produto.frente}<br/>
                      Sensor: {produto.sensor}
                    </p>
                    <div  className="valorProdutoCarrinho">
                      <div>
                        {moeda(parseFloat(produto.valorDesconto))}
                      </div>
                      <div>
                        {moeda(parseFloat(produto.valorDesconto)*parseInt(produto.quantidade))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='resumoCarrinho'>
              <div className='linhaResumoCarrinho'>
                <h2>Resumo</h2>
                <div>
                  <span className='totalItensCarrinho'>
                  {quantidadeTotalCarrinho}
                  </span>
                  Itens
                </div>
              </div>
              <div className='linhaResumoCarrinho'>
                Subtotal
                <span className='totalItensCarrinho'>
                  {moeda(totalDesconto)}
                </span>
              </div>
              <div className='linhaResumoCarrinho'>
                Frete
                <span className='totalItensCarrinho'>
                  R$ 0,00
                </span>
              </div>
              <div className='separador'></div>
              <div className='linhaResumoCarrinho valorTotal'>
                Total no pix
                <span className='totalItensCarrinho'>
                  {moeda(totalDesconto)}
                </span>
              </div>
              <div className='linhaResumoCarrinho'>
                Ou  
                <span className='totalItensCarrinho'>
                  {moeda(total)} no cartão
                </span>
              </div>
              <button onClick={finalizarCompra} className='comprar'>
                Continuar a compra
              </button>
              
            </div>
        </div>
      </main>
      <Footer footerText={footerText} />
    </div>
  );
}

export default Carrinho;
